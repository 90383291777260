<template>
  <b-card title="">
    <b-tabs>
      <b-tab @click="$router.push('/seo-classical-category')">
        <template
          #title
          active
        >
          <feather-icon icon="FolderIcon" />
          <span>Classical Category</span>
        </template>

      </b-tab>
      <b-tab @click="$router.push('/seo-online-category')">
        <template #title>
          <feather-icon icon="FolderIcon" />
          <span>Online Category</span>
        </template>

      </b-tab>

    </b-tabs>
    <router-view v-slot="{ Component, route }">
      <SeoClassicalCourse v-if="route.name === 'seo-classical-category' " />
      <router-view v-else>
        <component :is="Component" />
      </router-view>
    </router-view>
  </b-card>
</template>

<script>
import BCardCode from '@core/components/b-card-code'
import {
  BTabs, BTab, BCard, BCardText,
} from 'bootstrap-vue'

import SeoClassicCategory from './SeoClassicCategory.vue'
import SeoOnlineCategory from './SeoOnlineCategory.vue'
// import CategoriesList from './categoriesList.vue';
// import AddCategory from './AddCategory.vue';

export default {
  components: {
    BCardCode,
    BCard,
    BCardText,
    BTabs,
    BTab,

    SeoClassicCategory,
    SeoOnlineCategory,
  },
  data() {
    return {

    }
  },
}
</script>
